import { AnimatePresence, motion } from 'framer-motion';
import React, { useState, memo, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { SwapRevertIcon, BSCIcon, EtherIcon } from 'components/Icons';
import { Text } from 'components/Swap/SwapBoard';
import TransactionItem from './TransactionItem';

const variants = {
  hide: (direction) => {
    return {
      x: direction > 0 ? '-10%' : '10%',
      opacity: 0,
    };
  },
  show: {
    x: 0,
    opacity: 1,
    zIndex: 1,

    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 30,
      duration: 0.15,

    },
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? '-10%' : '10%',
      opacity: 0,
      transition: {
        duration: 0.15,
      },
    };
  },
};

const BoardWrapper = styled.div`
  overflow: hidden;
`;

export const LineWrapper = styled.div`
  padding: 1rem;
`;

export const LineBreak = styled.div`
  width: 100%;
  height: 1px;
  background: #e7eaf3;
`;

export const TxRound = styled.div`
  background: #e7eaf3;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  border-radius: 50%;
`;

export const TextLink = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: #0a58ca;
  text-decoration: none;
  margin-left: 8px;
  display: block;
  width: 100%;
  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
  :hover {
    text-decoration: underline;
  }
`;

export const StyledImg = styled.img`
  width: ${({ width }) => width ?? '100%'};
  height: auto;
`;

export const TransactionAmount = styled.div`
  position: relative;
  padding-left: 1.15rem;
  letter-spacing: 0.8px;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: #77838f;
  font-size: 12px;
  background: rgba(119, 131, 143, 0.1);
  display: inline-block;
  :before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    border-top: 0.8rem solid transparent;
    border-bottom: 0.8rem solid transparent;
    border-left: 0.8rem solid #fff;
    width: 15px;
  }
`;

const StyledSwapIcon = styled(SwapRevertIcon)`
  position: absolute;
  left: 50%;
  top: 50%;
  display: none;
  transform: translate(-50%, -50%) rotate(90deg);
  @media screen and (min-width: 768px) {
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: fit-content;
  }
`;

const TransactionsBoard = ({ txList, page, direction }) => {
  const [ethList, setEthList] = useState([]);
  const [bscList, setBscList] = useState([]);

  useEffect(() => {
    if (txList) {
      setEthList(txList.eth.data);
      setBscList(txList.bsc.data);
    }
  }, [txList]);

  return (
    <BoardWrapper>
      <div className="row">
        <div className="col-12 col-md-6">
          <LineWrapper>
            <div className="d-flex align-items-center">
              <Text fontSize="18px" fontWeight={400} className="d-none d-md-block">
                Latest Transactions
              </Text>
              <Text fontSize="18px" fontWeight={400} className="d-flex align-items-center m-auto me-md-0">
                <EtherIcon width="24px" className="me-2" /> Ethereum Network
              </Text>
            </div>
          </LineWrapper>
        </div>

        <SwapRevertIcon width="16px" height="16px" style={{ transform: 'rotate(90deg)' }} className="d-md-none" />
        <div className="col-12 col-md-6">
          <LineWrapper>
            <div className="d-flex align-items-center">
              <Text fontSize="18px" fontWeight={400} className="d-none d-md-block">
                Latest Transactions
              </Text>
              <Text fontSize="18px" fontWeight={400} className="d-flex align-items-center m-auto me-md-0">
                <BSCIcon width="24px" className="me-2" /> BSC Network
              </Text>
            </div>
          </LineWrapper>
        </div>
      </div>
      <LineBreak />
      <AnimatePresence exitBeforeEnter custom={direction}>
        <motion.div
          custom={direction}
          className="overflow-hidden position-relative"
          style={{ minHeight: 330 }}
          variants={variants}
          initial="hide"
          animate="show"
          exit="exit"
          key={page}
         
        >
          {ethList?.map((transaction, index) => (
            <Fragment key={transaction.id}>
              <div className="row align-items-center position-relative" key={transaction.id}>
                <div className="col-12 col-md-6">
                  <TransactionItem item={transaction} network="eth" />
                </div>
                <StyledSwapIcon width="16px" height="16px" />
                <div className="col-12 col-md-6 mt-3 mt-md-0">
                  <TransactionItem item={bscList[index]} network="bsc" />
                </div>
              </div>
              <LineBreak />
            </Fragment>
          ))}
        </motion.div>
      </AnimatePresence>
    </BoardWrapper>
  );
};

export default memo(TransactionsBoard);
