import React from 'react';
import { LineWrapper, TxRound, TextLink, TransactionAmount, StyledImg } from './index';
import { Text } from '../Swap/SwapBoard';
import { formatTotalCoin } from 'utils';
import { SWAPCONTRACT_ADDRESS } from 'AppContext';

const formatTime = (time) => {
  const event = new Date(time);
  const txTime = event.toLocaleDateString('en', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return txTime;
};

const formatToken = (address) => {
  switch (address) {
    case '0xC1b6157D43e208B093847967c589488B517a9092':
      return 'STARGRAM';
    case '0x0727E6688C3Cdc4177bEB65fA6C6670830d7e4d7':
      return 'STARGRAM';
    case '0x7713bCda5106424FF6B0Ae1e7Fa05C3f4ae3ecB0':
      return 'GSMT';
    case '0x5ce58bC6788b26A9F896caC9BC0C5fF2e33736c7':
      return 'SNAIL';
    default:
      break;
  }
};

const formatAddress = (address) => `${address.slice(0, 5)}...${address.slice(-4)}`;

const TransactionItem = ({ item, network }) => {
  let tokenValue;
  const [left, right] = item.value.toString().split('.');
  if (!right) tokenValue = left;
  else if (right.length > 3) tokenValue = `${left}.${right.slice(0, 3)}`;
  else tokenValue = `${left}.${right}`;


  return (
    <LineWrapper>
      <div
        className="flex-column flex-md-row d-flex justify-content-between align-items-md-center flex-wrap"
        style={{ margin: -8 }}
      >
        <div className="d-flex align-items-center col p-2">
          <TxRound>Tx</TxRound>
          <div>
            <TextLink
              as="a"
              href={network === 'eth' ? `https://etherscan.io/tx/${item.hash}` : `https://bscscan.com/tx/${item.hash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.hash.slice(0, 12)}...
              <span className="d-md-none">{item.hash.slice(-12)}</span>
            </TextLink>
            <Text color="#808080" fontSize="12px" fontWeight={400}>
              {formatTime(item.timestamp)}
            </Text>
          </div>
        </div>
        <div className="col p-2">
          <div className="d-flex">
            <span>From:</span>
            <TextLink
              as="a"
              href={
                network === 'eth'
                  ? `https://etherscan.io/address/${item.from}`
                  : `https://bscscan.com/address/${item.from}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatAddress(item.from)}
            </TextLink>
          </div>
          <div className="d-flex">
            To:
            <TextLink
              fontWeight={400}
              as="a"
              href={
                network === 'eth'
                  ? `https://etherscan.io/address/${SWAPCONTRACT_ADDRESS}`
                  : `https://bscscan.com/address/${item.to}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {network === 'eth' ? formatAddress(SWAPCONTRACT_ADDRESS) : formatAddress(item.to)}
            </TextLink>
          </div>
        </div>
        <div className="p-2">
          <TransactionAmount className="text-truncate">
            <div className="d-flex align-items-center pe-2">
              <Text className="p-2" fontSize="14px" color="inherit" fontWeight={400}>
                {formatTotalCoin(+tokenValue)} {formatToken(item.token)}
              </Text>

              <StyledImg src={`/tokens/${formatToken(item.token)}.svg`} width="16px" />
            </div>
          </TransactionAmount>
        </div>
      </div>
    </LineWrapper>
  );
};

export default React.memo(TransactionItem);
