import React, { createContext, useState, useEffect } from 'react';
import getWeb3, { getWeb3BSC } from 'getWeb3';
import { SHTAbi, SwapContractAbi } from 'contracts';
import { getTokenContract } from 'hooks/useWeb3';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';

export const SWAPCONTRACT_ADDRESS = process.env.REACT_APP_BRIDGE_ADDRRESS;
export const SUPPORT_NETWORK = +process.env.REACT_APP_NETWORK_CHAIN;
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const axiosInstance = axios.create({ baseURL: BASE_API_URL });
const SHTAddress = process.env.REACT_APP_SHT_ADDRESS;

const getSwapStakingContract = (web3) => new web3.eth.Contract(SwapContractAbi, SWAPCONTRACT_ADDRESS);

const TokenToSwap = (tokenList) =>
  Object.keys(tokenList).map((key) => ({
    name: tokenList[key].name,
    ratio: tokenList[key].rate,
    address: key,
    decimals: tokenList[key].decimals,
  }));

const TokenToReceive = (tokenList, address) => tokenList[address].listSwap.map((token) => token);

const ROW_PER_PAGE = 5;

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [web3, setWeb3] = useState();
  const [web3Bsc, setWeb3Bsc] = useState();
  const [account, setAccount] = useState(null);
  const [networkId, setNetworkId] = useState(null);
  const [swapList, setSwapList] = useState([]);
  const [receiveList, setReceiveList] = useState(null);
  const [tokenSwap, setTokenSwap] = useState(null);
  const [tokenReceive, setTokenReceive] = useState(null);
  const [boardLoading, setBoardLoading] = useState(false);
  const [hasAccountChanged, setHasAccountChanged] = useState(false);
  const [tokenList, setTokenList] = useState(null);
  const [txList, setTxList] = useState({ eth: [], bsc: [] });
  const [lockList, setLockList] = useState([]);
  const [swapContract, setSwapContract] = useState(null);
  const [balance, setBalance] = useState({});

  const onTokenSwapChoose = (address) => {
    const newTokenSwap = swapList.find((element) => element.address === address);
    const newReceiveList = TokenToReceive(tokenList, address);
    setReceiveList(newReceiveList);
    setTokenSwap(newTokenSwap);
    setTokenReceive(newReceiveList[0]);
  };

  const onTokenReceiveChoose = (address) => {
    const newToken = receiveList.find((element) => element.address === address);
    setTokenReceive(newToken);
  };

  const handleConnect = async () => {
    await window.ethereum.enable();
    withReactContent(Swal).fire({
      icon: 'warning',
      allowOutsideClick: false,

      title: <span style={{ color: '#f8bb86' }}>Caution!</span>,
      html: (
        <span style={{ color: 'rgb(128, 128, 128)', fontWeight: 400 }}>
          On Snail Swap, the converted tokens will be locked within 1 year.
          <br />
          The GAS fee will be charged, and the transaction will be canceled when the token is locked at Metamask.
        </span>
      ),
    });
  };

  const handleLogout = () => {
    setAccount(null);
    setTokenSwap(null);
    setTokenReceive(null);
    setReceiveList(null);
  };
  const switchNetworkHandler = async (chainId) => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: `0x${Number(chainId).toString(16)}`,
          },
        ],
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getSHTLockList = async (account) => {
    try {
      const lockListRes = await (await fetch(`${BASE_API_URL}/swap/my-transaction/${account}/bsc`)).json();
      setLockList(lockListRes.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDefaultBalance = async (web3bsc, web3eth, account, tokenAddress) => {
    const tokenContract = getTokenContract(web3bsc, tokenAddress, SHTAbi);
    const [shtBalance, ethBalance] = await Promise.allSettled([
      tokenContract.methods.balanceOf(account).call(),
      web3eth.eth.getBalance(account),
    ]);
    setBalance({
      eth: web3eth.utils.fromWei(ethBalance.value.toString(), 'ether'),
      sht: web3bsc.utils.fromWei(shtBalance.value.toString(), 'ether'),
    });
  };

  const getSwapList = async () => {
    try {
      const response = await (await fetch(BASE_API_URL + '/swap/get-rate')).json();

      setTokenList(response);
      setSwapList(TokenToSwap(response));
    } catch (error) {
      console.log(error);
    }
  };

  const getTxList = async (page = 1) => {
    setBoardLoading(true);
    try {
      const [eth, bsc] = await Promise.allSettled([
        (
          await fetch(BASE_API_URL + `/swap/transaction/eth?limit=5&order=desc&offset=${(page - 1) * ROW_PER_PAGE}`)
        ).json(),
        (
          await fetch(BASE_API_URL + `/swap/transaction/bsc?limit=5&order=desc&offset=${(page - 1) * ROW_PER_PAGE}`)
        ).json(),
      ]);
      setTxList({ eth: eth.value, bsc: bsc.value });
    } catch (error) {
      console.log(error);
    }
    setBoardLoading(false);
  };

  useEffect(() => {
    const init = async () => {
      if (window.ethereum) {
        const [web3eth, web3bsc] = await Promise.allSettled([getWeb3(), getWeb3BSC()]);
        const [networkId, accounts] = await Promise.allSettled([
          web3eth.value.eth.net.getId(),
          web3eth.value.eth.getAccounts(),
        ]);

        setSwapContract(getSwapStakingContract(web3eth.value));
        setWeb3(web3eth.value);
        setWeb3Bsc(web3bsc.value);
        setNetworkId(networkId.value);

        if (accounts.value.length > 0) {
          setAccount(accounts.value[0]);
          await getDefaultBalance(web3bsc.value, web3eth.value, accounts.value[0], SHTAddress);
        }
        window.ethereum.on('accountsChanged', async (accounts) => {
          setHasAccountChanged(true);
          if (!accounts[0]) {
            setAccount(null);
            setTokenSwap(null);
            setTokenReceive(null);
            setReceiveList(null);
          } else {
            setTokenSwap(null);
            setTokenReceive(null);
            setAccount(web3eth.value.utils.toChecksumAddress(accounts[0]));
            await getDefaultBalance(web3bsc.value, web3eth.value, accounts[0], SHTAddress);
          }
        });
        window.ethereum.on('chainChanged', (_chainId) => window.location.reload());
      }
    };
    init();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await Promise.allSettled([getSwapList(), getTxList()]);
      } catch (error) {
        console.log('error:', error);
      }
    })();
  }, []);

  useEffect(() => {
    if (account) {
      getSHTLockList(account);
      return;
    }
    setLockList([]);
  }, [account]);

  return (
    <AppContext.Provider
      value={{
        account,
        handleConnect,
        handleLogout,
        switchNetworkHandler,
        networkId,
        receiveList,
        swapList,
        tokenReceive,
        tokenSwap,
        onTokenSwapChoose,
        onTokenReceiveChoose,
        web3,
        web3Bsc,
        hasAccountChanged,
        txList,
        getTxList,
        boardLoading,
        swapContract,
        lockList,
        getSHTLockList,
        balance,
        getDefaultBalance,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
