import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import TransactionsBoard from '../TransactionBoard';
import { ChevronDown, EtherIcon, SwapIcon, BSCIcon, ArrowLeft, RefreshIcon, CopyIcon } from '../Icons';
import { AnimatePresence, motion } from 'framer-motion';
import { SUPPORT_NETWORK } from 'AppContext';
import TokensAddress from 'config/Token';
import BigNumber from 'bignumber.js';
import { MultiplyBigNum } from 'utils';

const errorVariants = {
  show: {
    opacity: 1,
  },
  hide: {
    opacity: 0,
  },
};

const StyledCopyIcon = styled(CopyIcon)`
  cursor: pointer;
`;

const ToolTipCopied = styled.div`
  background: #333;
  box-shadow: 0 12px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 8px;
  position: absolute;
  top: -40px;
  font-size: 13px;
  right: 0;
  color: #000;
  font-weight: 600;
  text-align: center;
  transform: translateX(calc(100% - 42px));
  color: #fff;
  /* background: linear-gradient(214.02deg, #d71479 6.04%, #f87421 92.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  :after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 28px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #333;
  }
`;

const SwapBoard = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px #00000040;
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  /* @media screen and (min-width:768px) {
    max-width:600px;
  } */
  /* margin: 0 auto; */
  padding: 2rem 1rem 1rem;
`;

const BoardWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  position: relative;
`;

export const Text = styled.p`
  margin-left: 8px;
  color: ${({ color }) => color ?? '#000'};
  font-weight: ${({ fontWeight }) => fontWeight ?? '700'};
  font-size: ${({ fontSize }) => fontSize ?? '34px'};
  ${({ textTransform }) => textTransform && `text-transform:${textTransform};`}
  ${({ fontFamily }) => fontFamily === 'Playfair' && 'font-family: Playfair Display SC;'}
  line-height: ${({ lineHeight }) => lineHeight ?? 1};
  text-align: ${({ textAlign }) => textAlign ?? 'start'};
  ${({ mb }) => mb && `margin-bottom: ${mb};`}
  ${({ truncate }) =>
    truncate &&
    `
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
    width:150px;
    `}
    ${({ linear }) =>
    linear &&
    `
    background: linear-gradient(214.02deg, #D71479 6.04%, #F87421 92.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    `}
`;

const StyledImg = styled.img`
  width: ${({ width }) => width ?? '100%'};
  height: auto;
`;

export const StyledButton = styled.button`
  --main-color: linear-gradient(214.02deg, #d71479 6.04%, #f87421 92.95%);
  text-decoration: none;
  outline: none;
  border: none;

  background: var(--main-color);
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  padding: 1rem;
  transition: 0.1s ease-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 30px;
  font-size: ${({ fontSize }) => fontSize ?? '16px'};
  &.success {
    background: var(--main-color);
    border-radius: 10px;
  }
  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  :not(:disabled) {
    :hover {
      filter: brightness(1.1);
    }
  }
`;

export const OutlineButton = styled(StyledButton)`
  border-radius: 30px;
  background: rgb(247, 248, 250);
  position: relative;
  color: #ed1c51;
  :hover {
    color: #fff;
  }
  :before {
    border-radius: 30px;
    position: absolute;
    content: '';
    background: linear-gradient(214.02deg, #d71479 6.04%, #f87421 92.95%);
    top: -2px;
    left: -2px;
    bottom: -2px;
    right: -2px;
    position: absolute;
    z-index: -1;
  }
`;

const InputTokenWrapper = styled.div`
  text-align: left;
  padding: 0.75rem;
  border: 1px solid rgba(196, 196, 196, 0.5);
`;

export const Flex = styled.div`
  display: flex;
  ${({ alignItems }) => alignItems && `align-items:${alignItems};`}
  ${({ justifyContent }) => justifyContent && `justify-content:${justifyContent};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap:${flexWrap};`}
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  color: #b1b1b1;
  font-size: 18px;
  padding-right: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  :read-only {
    cursor: not-allowed;
  }
  &::placeholder {
    color: #e6e6e6;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

const StyledInputLabel = styled.p`
  font-weight: 400;
`;

const SelectTokenBox = styled(StyledButton)`
  padding: 0.5rem;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  color: #000;
  background: transparent;
  border: 1px solid #000;

  ${({ disabled }) =>
    disabled
      ? `opacity:0.4;`
      : `:hover {
    opacity: 0.7;
    color: #000;
  }`}
  ${({ hide }) => hide && `display:none;`}
`;

const SwapLine = styled(Flex)`
  position: relative;
  :before {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background: #1c1924;
  }
`;

const LineWrapper = styled.div`
  padding: 1rem;
`;

const TransactionBoardWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid #e7eaf3;
  width: 100%;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const SpinnerWrapper = styled.div`
  margin: auto;
`;

const SpinnerLoader = styled.div`
  width: 2rem;
  height: 2rem;
`;

const ArrowRight = styled(ArrowLeft)`
  transform: rotate(180deg);
`;

const PaginateButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0 8px;
  :disabled {
    svg {
      stroke: #808080;
    }
  }
`;

const ApproveButton = styled.button`
  outline: none;
  border: none;
  text-transform: capitalize;
  font-weight: 600;
  padding: 1rem;
  transition: 0.2s ease-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 30px;
  width: 100%;
  color: #ed1c51;
  border: 1px solid #ed1c51;

  :disabled {
    color: #000;
    border: 1px solid rgba(0, 0, 0, 0.5);
    opacity: 0.4;
    :hover {
      opacity: 0.4;
    }
    cursor: not-allowed;
  }
  :not(:disabled) {
    :hover {
      background: #fff;
    }
  }
`;

export const GradientText = styled(Text)`
  background: -webkit-linear-gradient(214.02deg, #d71479 6.04%, #f87421 92.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StyledF5Icon = styled(RefreshIcon)`
  cursor: pointer;
  fill: #606060;
  transition: 0.1s ease-in;
  :active {
    transform: rotate(90deg);
  }
`;

const TokenLink = styled.a`
  display: inline-block;
  font-size: 14px;
  border: 1px solid #958e8e;
  padding: 4px 8px;
  color: #dc3545 !important;
  border-radius: 50px;
  background: #f8f9fa;
  margin-right: 3px;
  transition: 0.2s ease-out;
  :hover {
    opacity: 0.8;
  }
`;

const shiningAnimate = keyframes`

from { 
    background-repeat:no-repeat; 
    background-image:-webkit-linear-gradient( top left, 
                    rgba(255, 255, 255, 0.0) 0%, 
                    rgba(255, 255, 255, 0.0) 45%, rgba(255, 255, 255, 0.5) 48%, 
                    rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.5) 52%, 
                    rgba(255, 255, 255, 0.0) 57%, rgba(255, 255, 255, 0.0) 100% ); 
    background-position:-250px -250px; background-size: 600px 600px;
    opacity:0.5;
   } 
    
  to { 
    background-repeat:no-repeat; 
    background-position:250px 250px;
    opacity:1;
  } 
`;

const opacity = keyframes`
  0% {
    opacity:0.8;
  }
  50% {
    opacity:1;

  }
  100% {
    opacity:0.8;

  }
`;

const StyledSwapButton = styled(StyledButton)`
  position: relative;

  :not(:disabled) {
    animation: ${opacity} 3s linear infinite;
    :after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      animation: ${shiningAnimate} 3s linear infinite;
    }
  }
`;

const RenderApproveButton = (account, handleApprove, tokenSwap, networkId, modalLoading, balanceSwap, amount,isButtonClick) => {
  const noAbleToApprove =
    !account || !tokenSwap || networkId !== SUPPORT_NETWORK || modalLoading || +amount > balanceSwap || amount <= 0 || isButtonClick;
  return (
    <ApproveButton disabled={noAbleToApprove} onClick={handleApprove}>
      Approve
    </ApproveButton>
  );
};

const RenderSwapButton = (account, isSwapable, handleSwap, amount, tokenAddress, modalLoading) => {
  return (
    <StyledSwapButton
      disabled={!isSwapable || modalLoading || !account}
      onClick={() => handleSwap(amount, account, tokenAddress)}
      style={{ width: '100%' }}
    >
      Swap & Lock
    </StyledSwapButton>
  );
};

const TokenSwap = ({
  account,
  onAmountChangeHandler,
  networkId,
  tokenSwap,
  handleApprove,
  tokenReceive,
  onSwapModalOpen,
  onReceiveModalOpen,
  handleSwap,
  setMaxAmount,
  state,
  txList,
  getList,
  boardLoading,
  refreshTokenBalanceHandler,
  onShowVideoHandler,
  balance,
  isButtonClick
}) => {
  const { balanceSwap, amount, isSwapable, isApprove, modalLoading, feeTx, feeSwap } = state;

  const [[page, maxPage, direction], setPage] = React.useState([1, 1, 0]);
  const [copyList, setCopyList] = useState({ 1: false, 2: false, 3: false });
  const copyRef = React.useRef(null);
  copyRef.current = copyList;

  const onCopyHandler = (order, data) => {
    navigator.clipboard.writeText(data);
    setCopyList({ ...copyRef.current, [order]: true });
    setTimeout(() => setCopyList({ ...copyRef.current, [order]: false }), 2000);
  };

  React.useEffect(() => {
    if (txList) {
      const totalPage = Math.ceil(txList.eth.total / 5) || 1;
      setPage([page, totalPage, direction]);
    }
  }, [txList, page]);

  const onPageChangeHandler = (newPage) => {
    getList(newPage);
    setPage([newPage, maxPage, newPage < page ? 1 : -1]);
  };

  return (
    <>
      <BoardWrapper>
        <Text
          color="#000"
          fontFamily="Playfair"
          fontWeight="900"
          fontSize="34px"
          textTransform="uppercase"
          textAlign="center"
          lineHeight="40px"
          mb="12px"
          className="ms-0"
        >
          Token Swap{' '}
        </Text>
        <Text color="#808080" textAlign="center" fontSize="20px" fontWeight="400" mb="34px" className="ms-0">
          The best place to swap old token to new token
        </Text>

        <SwapBoard className="mb-5">
          <Flex justifyContent="space-between" alignItems="center">
            <Text color="#000" fontSize="22px" style={{ marginBottom: 16 }} className="ms-0 mb-4">
              Swap
            </Text>
            <Text
              color="#0d6efd"
              fontWeight={500}
              fontSize="14px"
              style={{
                marginBottom: 16,
                cursor: 'pointer',
                textDecoration: 'underline',
                textDecorationColor: '#0d6efd',
              }}
              className="ms-0 mb-4"
              onClick={onShowVideoHandler}
            >
              How to swap?
            </Text>
          </Flex>
          <InputTokenWrapper>
            <Flex justifyContent="space-between" className="pb-3 align-items-center">
              <Flex alignItems="center">
                <StyledInputLabel className="d-none d-sm-block me-2">From </StyledInputLabel>
                <EtherIcon width="24px" className="" />
                <StyledInputLabel className="ms-2">Ethereum Network</StyledInputLabel>
              </Flex>

              <StyledInputLabel onClick={tokenSwap ? setMaxAmount : undefined}>Balance: {balanceSwap}</StyledInputLabel>
            </Flex>
            <Flex className="mb-2">
              <StyledInput
                type="number"
                className="col-6"
                placeholder="0.000"
                step="0.001"
                pattern="^\d*(\.\d{0,2})?$"
                onChange={onAmountChangeHandler}
                value={amount}
                readOnly={!tokenSwap}
              />
              <StyledButton className="col-2 p-0" disabled={!tokenSwap} onClick={setMaxAmount}>
                Max
              </StyledButton>
              <SelectTokenBox
                className="col-4  "
                onClick={account && networkId === SUPPORT_NETWORK ? onSwapModalOpen : null}
                disabled={!account || networkId !== SUPPORT_NETWORK}
              >
                {!tokenSwap ? (
                  <>Token Swap</>
                ) : (
                  <>
                    <StyledImg src={`/tokens/${tokenSwap.name}.svg`} width="24px" className="me-1" />
                    <span className="w-100 text-truncate">{tokenSwap.name}</span>
                  </>
                )}
                <ChevronDown width="12px" className="ms-1" />
              </SelectTokenBox>
            </Flex>
          </InputTokenWrapper>
          <SwapLine justifyContent="center" className="my-2">
            <SwapIcon width="45px" style={{ zIndex: 1 }} />
          </SwapLine>
          <InputTokenWrapper>
            <Flex alignItems="center" className="pb-3">
              <Flex alignItems="center">
                <StyledInputLabel>To</StyledInputLabel>

                <BSCIcon width="24px" className="mx-2" />
                <StyledInputLabel>BSC Network</StyledInputLabel>
              </Flex>
              {tokenReceive && (
                <div className="ms-auto d-flex">
                  <StyledF5Icon width="16px" className="me-2" onClick={refreshTokenBalanceHandler} />

                  <StyledInputLabel>
                    Balance: {balance?.sht?.length > 7 ? (+balance?.sht).toFixed(6) : balance?.sht}
                  </StyledInputLabel>
                </div>
              )}
            </Flex>

            <Flex>
              <StyledInput
                type="text"
                placeholder="0"
                className="col-7"
                readOnly
                value={amount && MultiplyBigNum(amount, tokenSwap?.ratio)}
              />

              <SelectTokenBox className="col-5" onClick={tokenReceive ? onReceiveModalOpen : null} hide={!tokenReceive}>
                <StyledImg src={`/tokens/${tokenReceive?.name}.svg`} width="24px" className="me-1" />
                {tokenReceive?.name} <ChevronDown width="12px" className="ms-2" />
              </SelectTokenBox>
            </Flex>
          </InputTokenWrapper>{' '}
          {tokenSwap && (
            <div>
              <div className="d-flex justify-content-between mt-2">
                <Text fontWeight={500} fontSize="14px" className="ms-0">
                  Price:
                </Text>
                <GradientText fontWeight={600} fontSize="14px" className="ms-0">
                  {1 / tokenSwap.ratio} {tokenSwap.name} per {tokenReceive.name}
                </GradientText>
              </div>
              {isApprove && (
                <div className="d-flex justify-content-between mt-2 align-items-center">
                  <Text fontWeight={500} fontSize="14px" className="ms-0">
                    Total Fee (estimated) :
                    <br /> Swap Fee + Gas Fee
                  </Text>
                  <GradientText fontWeight={700} fontSize="14px" className="ms-0" textTransform="uppercase">
                    ~{(+feeTx + feeSwap).toFixed(6)} Eth
                  </GradientText>
                </div>
              )}
            </div>
          )}
          <div className="my-4 mx-3">
            {!isApprove
              ? RenderApproveButton(account, handleApprove, tokenSwap, networkId, modalLoading, balanceSwap, amount,isButtonClick)
              : RenderSwapButton(account, isSwapable, handleSwap, amount, tokenSwap?.address, modalLoading)}
            {!isApprove && balanceSwap === 0 && tokenSwap && (
              <Text fontWeight={500} fontSize="14px" className="ms-0 mt-2 text-center" color="red">
                Your balance must be greater than 0 to approve
              </Text>
            )}
          </div>
        </SwapBoard>
        <div className="row justify-content-center align-items-center mb-4 gy-3">
          {TokensAddress.map((data) => (
            <div key={data.id} className="col col-md-6 col-lg-4">
              <div className="mb-2 position-relative d-flex  align-items-center">
                <div className=" d-flex align-items-center me-2">
                  {(data.chain === 'Ether' && <EtherIcon width="24px" />) ||
                    (data.chain === 'Bsc' && <BSCIcon width="24px" />)}
                  <Text fontSize="16px">{data.name}</Text>
                </div>
                <div className="position-relative">
                  <StyledCopyIcon onClick={() => onCopyHandler(data.id, data.address)} style={{ width: '16px' }} />
                  {copyList[data.id] && <ToolTipCopied>Copied!</ToolTipCopied>}
                </div>
              </div>
              <TokenLink
                target="_blank"
                rel="noopener noreferrer"
                href={`https://${data.url}/token/${data.address}`}
                className="text-decoration-none"
              >
                {data.address}
              </TokenLink>
            </div>
          ))}
        </div>
        <Text
          color="#000"
          fontFamily="Playfair"
          fontWeight="700"
          fontSize="34px"
          textTransform="uppercase"
          textAlign="center"
          lineHeight="40px"
          mb="12px"
          className="ms-0"
        >
          Latest Transactions
        </Text>

        <TransactionBoardWrapper className="">
          {boardLoading && (
            <div className="position-absolute translate-middle top-50 start-50" style={{ zIndex: 10000 }}>
              <SpinnerWrapper>
                <SpinnerLoader className="spinner-border text-secondary" />
              </SpinnerWrapper>
            </div>
          )}

          <TransactionsBoard txList={txList} page={page} direction={direction} />

          <LineWrapper>
            <div className="d-flex justify-content-center align-items-center">
              <PaginateButton
                disabled={isNaN(page) || page <= 1 || boardLoading}
                onClick={() => onPageChangeHandler(page - 1)}
              >
                <ArrowLeft width="24px" />
              </PaginateButton>
              <Text fontWeight={500} fontSize="14px" className="ms-0">
                Page {page} of {maxPage || 0}
              </Text>

              <PaginateButton
                disabled={isNaN(page) || page === maxPage || boardLoading}
                onClick={() => onPageChangeHandler(page + 1)}
              >
                <ArrowRight width="24px" />
              </PaginateButton>
            </div>
          </LineWrapper>
        </TransactionBoardWrapper>
      </BoardWrapper>
    </>
  );
};

export default React.memo(TokenSwap);
