import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { GradientText, Text } from 'components/Swap/SwapBoard';
import { formatTotalCoin, toDate, DaysMinus } from 'utils';

const Wrapper = styled.div`
  overflow-x: scroll;
  min-height: calc(100vh - 100px);
  @media screen and (min-width: 576px) {
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const Table = styled.table`
  border: 1px solid #e7eaf3;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 8px rgb(0 0 0 / 20%);
  margin-bottom: 100px;
  transition: 0.2s ease-in-out;
  
`;

const THead = styled.thead`
  border-top: 1px solid #e7eaf3 !important;
  border-radius: 10px;
`;
const TBody = styled.tbody`
  height: 100%;
  overflow: scroll;
  border-top: 1px solid #e7eaf3 !important;
`;

const StyledImg = styled.img`
  width: ${({ width }) => width ?? '100%'};
  height: auto;
`;

const StyledGradientLink = styled.div`
  margin-left: 8px;
  color: ${({ color }) => color ?? '#000'};
  font-weight: ${({ fontWeight }) => fontWeight ?? '700'};
  font-size: ${({ fontSize }) => fontSize ?? '34px'};
  ${({ textTransform }) => textTransform && `text-transform:${textTransform};`}
  ${({ fontFamily }) => fontFamily === 'Playfair' && 'font-family: Playfair Display SC;'}
  line-height: ${({ lineHeight }) => lineHeight ?? 1};
  text-align: ${({ textAlign }) => textAlign ?? 'start'};
  ${({ mb }) => mb && `margin-bottom: ${mb};`}
  ${({ truncate }) =>
    truncate &&
    `
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
    width:150px;
    `}
  :hover {
    opacity: 0.7;
  }
  background: linear-gradient(214.02deg, #d71479 6.04%, #f87421 92.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const LockList = () => {
  const { lockList } = useContext(AppContext);
  return (
    <Wrapper
      as={motion.div}
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="hidden"
      className="container position-relative "
    >
      <div className="w-100 " style={{ marginTop: 100 }}>
        <Text className="mb-3 ms-0 text-uppercase text-center" fontFamily="Playfair">
          {lockList.length > 0 ? 'Your Tokens Lock Time' : 'You have no transaction'}
        </Text>
        {lockList.length > 0 && (
          <Table className="table table-striped">
            <THead>
              <tr>
                <th scope="col">
                  <Text fontSize="16px" color="#606060" fontWeight={500} className="ms-0 d-none d-md-block">
                    #
                  </Text>
                </th>
                <th scope="col">
                  <Text fontSize="16px" color="#606060" fontWeight={500} className="ms-0 ">
                    Transaction Hash{' '}
                  </Text>
                </th>
                <th scope="col">
                  <Text fontSize="16px" color="#606060" fontWeight={500} className="ms-0 d-none d-md-block">
                    Lock Time Start
                  </Text>
                </th>
                <th scope="col">
                  <Text fontSize="16px" color="#606060" fontWeight={500} className="ms-0">
                    Lock Time End
                  </Text>
                </th>
                <th scope="col">
                  <Text fontSize="16px" color="#606060" fontWeight={500} className="ms-0">
                    Amount
                  </Text>
                </th>
              </tr>
            </THead>

            <TBody className="position-relative">
              {lockList?.map((token, index) => (
                <tr key={token.locktimeStart}>
                  <th scope="row">
                    <GradientText fontSize="16px" className="ms-0 d-none d-md-inline-block">
                      {index + 1}
                    </GradientText>
                  </th>
                  <td>
                    <StyledGradientLink
                      as="a"
                      href={`https://bscscan.com/tx/${token.hash}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      fontSize="16px"
                      className="ms-0"
                    >
                      {token.hash.slice(0, 10)}...{token.hash.slice(-5)}
                    </StyledGradientLink>
                  </td>
                  <td>
                    <GradientText fontSize="16px" className="ms-0 d-none d-md-inline-block">
                      {token.timestamp} UTC
                    </GradientText>
                  </td>
                  <td>
                    <GradientText className="text-capitalize ms-0 d-inline-block" fontSize="16px">
                      {DaysMinus(toDate(token.timestamp))}
                    </GradientText>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <GradientText fontSize="16px" className="ms-0">
                        {formatTotalCoin(+token.value)}
                      </GradientText>
                      <StyledImg src="/tokens/SNAIL.svg" width="24px" className="ms-2" />
                    </div>
                  </td>
                </tr>
              ))}
            </TBody>
          </Table>
        )}
      </div>
    </Wrapper>
  );
};

export default React.memo(LockList);
