import React, { useContext, lazy, Suspense, useCallback, useState } from 'react';
import 'App.css';
import GlobalStyled from 'GlobalStyled';
import { Routes, Route } from 'react-router-dom';
import { AppContext } from 'AppContext';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';

import Navbar from 'components/Navbar';
import LockList from 'components/LockList';
import AccountModal from 'components/Modal/AccountModal';
import { ToastContainer } from 'react-toastify';

const Home = lazy(() => import('components/Home'));
const Swap = lazy(() => import('components/Swap'));

const Layout = styled.div`
  background: #fff;
  overflow: hidden;
`;

const Wrapper = styled.div`
  margin-top: 100px;
`;

function App() {
  const { account, networkId } = useContext(AppContext);
  const [isShowAcc, setShowAcc] = useState(false);
  const closeModalHandler = useCallback(() => setShowAcc(false), []);
  const openModalHandler = useCallback(() => setShowAcc(true), []);
  return (
    <Layout>
      <GlobalStyled />
      <Navbar account={account} networkId={networkId} openModalHandler={openModalHandler} />
      <AccountModal isShowAcc={isShowAcc} closeModalHandler={closeModalHandler} />
      <ToastContainer/>
      <Wrapper>
        <AnimatePresence exitBeforeEnter initial={false}>
          <Suspense fallback={<div />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/swap" element={<Swap networkId={networkId} account={account} />} />
              <Route path="/transactions" element={<LockList />} />
            </Routes>
          </Suspense>
        </AnimatePresence>
      </Wrapper>
    </Layout>
  );
}

export default App;
