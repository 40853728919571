import React, { useContext, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext, SUPPORT_NETWORK } from 'AppContext';
import { Flex, OutlineButton, Text } from 'components/Swap/SwapBoard';
import { isMobile } from 'react-device-detect';
import { ShowMoreIcon } from 'components/Icons';
import useClickOutside from 'hooks/useClickOutside';

export const NAV_HEIGHT = '100px';

const NavWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid #c4c4c4;
  background: #fff;
  z-index: 1000;
  height: ${NAV_HEIGHT};
`;

const StyledLogoIcon = styled.img`
  width: ${({ width }) => width ?? '100%'};
  height: auto;
  margin-right: 8px;
`;

const StyledLink = styled.div`
  transition: 0.2 ease-out;
  color: #000;
  font-weight: 700;
  ${({ active }) => active && `cursor:default;`}
  text-decoration: unset;
  position: relative;
  text-align: center;
  :hover {
    opacity: 0.8;
    color: #000;
  }
  ${({ active }) =>
    active &&
    `
  :after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    left: 0;
    background: -webkit-linear-gradient(
      214.02deg,
      #d71479 6.04%,
      #f87421 92.95%
    );
  }
  `}
`;

const StyledMenuButton = styled(ShowMoreIcon)`
  cursor: pointer;
`;

const NavLinkWrapper = styled.div`
  top: -10%;
  opacity: 0;
  z-index: -1;
  ${({ isShow }) => isShow && `top: calc(100% + 12px);opacity:1;z-index:1;padding:1rem;`}
  position: absolute;
  background: #fff;
  box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
  transition: 0.2s ease-in-out;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  flex-direction: column;
  left: 50%;
  width: 200px;
  transform: translateX(-50%);
  & > * + * {
    margin-top: 1rem;
  }
  @media screen and (min-width: 768px) {
    opacity: 1;
    flex-direction: row;
    box-shadow: unset;
    background: transparent;
    border: unset;
    border-radius: 0;
    z-index: 1;
    position: unset;
    justify-content: center;
    width: auto;
    transform: unset;
    * {
      margin-top: 0;
    }
  }
`;

const StyledAddress = styled(OutlineButton)`
  padding: 8px 1rem;
`;

const RenderConnectButton = (account, handleConnect, openModalHandler) => {
  if (window.ethereum) {
    if (!account) {
      return <OutlineButton onClick={handleConnect}>Connect Wallet</OutlineButton>;
    }
  } else {
    if (isMobile)
      return (
        <OutlineButton as="a" href="https://metamask.app.link/dapp/swap.snailhouse.io/">
          Connect Wallet
        </OutlineButton>
      );
    return (
      <OutlineButton as="a" target="_blank" href="https://metamask.io/download.html">
        Install Metamask
      </OutlineButton>
    );
  }

  if (account)
    return (
      <StyledAddress onClick={openModalHandler}>
        {account.slice(0, 5)}...{account.slice(-5)}
      </StyledAddress>
    );
};

const Navbar = ({ account, networkId, openModalHandler }) => {
  const { handleConnect, switchNetworkHandler } = useContext(AppContext);
  const { pathname } = useLocation();
  const [isShowLink, setShowLink] = React.useState(false);

  const navRef = useRef(null);
  useClickOutside(navRef, () => {
    if (isShowLink) setShowLink(false);
  });

  return (
    <NavWrapper>
      <Flex alignItems="center" justifyContent="space-between" className="container h-100 position-relative">
        <Flex alignItems="center" as={Link} to="/" style={{ textDecoration: 'unset' }}>
          <StyledLogoIcon src="/tokens/SNAIL.png" width="60px" />
          <Text
            color="#000"
            textTransform="uppercase"
            fontFamily="Playfair"
            className="d-none d-md-block"
            fontSize="24px"
          >
            Token swap
          </Text>
        </Flex>

        <div className="position-relative mx-md-auto me-auto ms-2" ref={navRef}>
          <StyledMenuButton width="24px" onClick={() => setShowLink((prev) => !prev)} className="d-md-none" />

          <NavLinkWrapper className="d-flex align-items-center" isShow={isShowLink}>
            <StyledLink as={Link} to="/swap" className="text-center" active={pathname.includes('swap')}>
              <Text fontSize="20px" fontWeight={500} className="ms-0" color="inherit">
                Swap
              </Text>
            </StyledLink>

            {account && (
              <StyledLink
                className={`ms-1 ms-md-5 text-center`}
                as={Link}
                to="/transactions"
                active={pathname.includes('transactions')}
              >
                <Text fontSize="20px" fontWeight={500} className="ms-0" color="inherit" textAlign="center">
                  My Transactions
                </Text>
              </StyledLink>
            )}
            <a
              className={`ms-1 ms-md-5 text-center`}
              target="_blank"
              href="/guide/snailhouse_swap_guide.pdf"
              style={{textDecoration: 'none', color: 'black'}}
            >
              <Text fontSize="20px" fontWeight={500} className="ms-0" color="inherit" textAlign="center">
                Guide
              </Text>
              </a>
          </NavLinkWrapper>
        </div>

        <Flex alignItems="center">
          {networkId !== SUPPORT_NETWORK && account ? (
            <OutlineButton onClick={() => switchNetworkHandler(SUPPORT_NETWORK)}>Wrong network</OutlineButton>
          ) : (
            RenderConnectButton(account, handleConnect, openModalHandler)
          )}
          {/* <OutlineButton style={{opacity:0, zIndex:-1}}>Easter egg</OutlineButton> */}
        </Flex>
      </Flex>
    </NavWrapper>
  );
};

export default Navbar;
