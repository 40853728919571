const TokensAddress = [
  {
    name: 'STARGRAM TOKEN',
    chain: 'Ether',
    address: '0x0727E6688C3Cdc4177bEB65fA6C6670830d7e4d7',
    url:'etherscan.io',
    id: 1,
  },
  {
    name: 'GSMT TOKEN',
    chain: 'Ether',
    address: '0x7713bCda5106424FF6B0Ae1e7Fa05C3f4ae3ecB0',
    url:'etherscan.io',
    id: 2,
  },
  {
    name: 'SNAIL TOKEN',
    chain: 'Bsc',
    address: '0x5ce58bC6788b26A9F896caC9BC0C5fF2e33736c7',
    url:'bscscan.com',
    id: 3,
  },
];

export default TokensAddress;
