import { ERC20Abi } from '../contracts';

export const getTokenContract = (web3, tokenAddress, Abi = ERC20Abi) => new web3.eth.Contract(Abi, tokenAddress);

export const convertToWeii = (web3, amount) => web3.utils.toWei(amount.toString(), 'ether');
export const convertToTokens = (web3, amount) => web3.utils.fromWei(amount.toString(), 'ether');

const useWeb3 = (account) => {
  const getBalanceOf = async (web3, tokenAddress, tokenDecimal) => {
    const tokenContract = getTokenContract(web3, tokenAddress);
    const balance = await tokenContract.methods.balanceOf(account).call();
    return +balance / 10 ** +tokenDecimal;
  };

  return {
    getBalanceOf,
  };
};

export default useWeb3;
