import React from 'react';

const ShowMore = (props) => {
  return (
    <svg
      enable-background="new 0 0 64 64"
      viewBox="0 0 64 64"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Arrow-13">
        <path d="m54.9210777 20.296875c-.15625-.3701172-.5185547-.6108398-.9208984-.6108398l-44 .0004883c-.4018555 0-.7646484.2407227-.9213867.6108398-.15625.3701172-.0756836.7983398.2045898 1.0864258l22 22.6274414c.1879883.1933594.4467773.3027344.7167969.3027344s.5288086-.109375.7167969-.3027344l22-22.6279297c.2802734-.2885742.3603515-.7163086.2041015-1.0864258z" />
      </g>
    </svg>
  );
};

export default ShowMore;
