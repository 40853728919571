import BigNumber from 'bignumber.js';

export const TokenToWei = (number, decimals) =>
  new BigNumber(+number).times(new BigNumber(10).pow(decimals)).toString();

export const MultiplyBigNum = (num1,num2) => BigNumber(+num1).multipliedBy(+num2).toString();

export const Round3Number = (number, decimals = 3) => {
  const d = Math.pow(10, decimals);
  return Number((parseInt(number * d) / d).toFixed(decimals));
};

export const formatTotalCoin = (num) => {
  const dollarUSLocale = Intl.NumberFormat('en-US', { maximumSignificantDigits: 8 });
  return dollarUSLocale.format(num);
};

export const toDate = (unixTime) => {
  const dateObject = new Date(unixTime);
  dateObject.setTime(dateObject.getTime() + 365 * 24 * 60 * 60 * 1000);
  return dateObject;
};

export const DaysMinus = (dateEnd) => {
  const currentDate = new Date();
  const difference = Math.abs(dateEnd - currentDate);
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(difference / (1000 * 60 * 60));
  const minutes = Math.floor(difference / (1000 * 60));

  if (days > 2) {
    return `${days} days left`;
  }
  if (days === 1) return `${days} day left`;
  if (hours > 2 && hours < 24) return `${hours} hrs left`;
  if (hours === 1) return `${hours} hr left`;
  if (hours === 1) return `${hours} hr left`;
  if (minutes > 1 && minutes < 60) return `${minutes} mins left`;
  if (minutes === 1) return `${hours} min left`;
  if (difference === 0) return 'Finished Locking';
};
